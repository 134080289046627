import React, { useState, useEffect } from 'react'
import getShipmentWebq from '../../../../utils/API/getShipmentWebq'

const OnTimeStatus = ({ props }) => {

    const [shipmentObj, setShipmentObj] = useState(null)

    useEffect(() => {
        if (props && props.webquotecontrol) {
            getShipmentWebq(props.webquotecontrol, 'status')
                .then((response) => {
                    console.log('OnTimeStatus getShipmentWebq response:', response)
                    setShipmentObj(response)
                })
                .catch((error) => {
                    console.log('OnTimeStatus getShipmentWebq error:', error)
                })
        }
    }, [])

    if (!props || !props?.webquotecontrol || !shipmentObj || !shipmentObj?.status?.onTimeStatus) { return null }

    // Define inline styles based on the shipment status
    const getStatusStyles = (status) => {
        switch (status.toLowerCase()) {
            case 'early':
                return {
                    backgroundColor: '#d4edda', // Light green background

                    borderColor: '#c3e6cb' // Green border
                }
            case 'at-facility':
                return {
                    backgroundColor: '#fff3cd', // Light yellow background
                    color: '#856404', // Dark yellow text
                    borderColor: '#ffeeba' // Yellow border
                }
            case 'delayed':
                return {
                    backgroundColor: '#f8d7da', // Light red background
                    color: '#721c24', // Dark red text
                    borderColor: '#f5c6cb' // Red border
                }
            case 'on-time':
                return {
                    backgroundColor: '#cce5ff', // Light blue background
                    borderColor: '#b8daff' // Blue border
                }
            default:
                return {
                    backgroundColor: '#e2e3e5', // Neutral grey background
                    color: '#383d41', // Neutral grey text
                    borderColor: '#d6d8db' // Neutral grey border
                }
        }
    }

    const status = shipmentObj?.status?.onTimeStatus
    const statusStyles = getStatusStyles(status)

    return (
        <div
            style={{
                ...statusStyles,
                padding: '0.25rem 0.25rem',
                borderRadius: '0.5rem',
                border: '1px solid',
                fontWeight: 'bold',
                display: 'inline-block',
                margin: '0.5rem 0',
                fontSize: '0.75rem',
                textAlign: 'center'
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
            </svg>
            <span className="ms-2 me-1">{status}</span>
        </div>
    )
}

export default OnTimeStatus